import React from "react"
import {Link} from "gatsby"
const sections = [
  {
    title: "Upload a MIDI Track",
    icon: "upload",
    link: "/conductor/upload",
    small: true,
  },
  {
    title: "Choose a Track",
    icon: "record-vinyl",
    link: "/conductor/track",
    small: true,
  },
  {
    title: "Pass the Baton",
    icon: "flag",
    link: "/conductor/pass",
    small: true,
  },
  {
    title: "Emergency Nokia",
    icon: "mobile-alt",
    link: "/conductor/nokia",
    small: true,
  },
  {
    title: "UniSynth",
    link: "/conductor/unisynth",
    icon: "circle-notch",
  },
  {
    title: "Midi Test",
    link: "/conductor/midi",
    icon: "lightbulb",
  },
]
export default ({ onChange }) => (
  <>
    {sections.map(({ title, icon, link, small }) => (
      <Link
      key={title}
        to={link}
        className={`col-span-2 ${
          small ? "sm:col-span-1" : ""
        } h-16 sm:h-32 bg-pink-400 hover:bg-pink-600 p-5 rounded text-white text-center flex items-center justify-center`}
      >
        <i className={`las la-${icon} text-2xl mr-2`}></i>
        {title}
      </Link>
    ))}
  </>
)
