import React, { useState } from "react"
import TrackSelect from "./TrackSelect"

export default ({ musicReadyCount, audioReadyCount }) => {
  const [music, setMusic] = useState(false)
  const [musicPrepped, setMusicPrepped] = useState(false)
  function sendMusic() {
    window.socket.emit("conductor-music")
    setMusicPrepped(true)
  }

  function startTheMusic() {
    window.socket.emit("conductor-start")
  }

  return (
    <>
      <div className="col-span-2 sm:col-span-1  bg-teal-200 p-5 rounded text-teal-800 text-center flex items-center justify-center">
        <i class="las la-volume-up text-2xl mr-2"></i>
        {audioReadyCount} audio ready
      </div>
      <div
        className={`col-span-2 sm:col-span-1 ${
          audioReadyCount === musicReadyCount
            ? "bg-teal-200 text-teal-800"
            : "bg-orange-200 text-teal-800"
        } p-5 rounded  text-center flex items-center justify-center`}
      >
        <i class="las la-music text-2xl mr-2"></i>
        {musicReadyCount} music ready
      </div>
      <TrackSelect
        values={["Levels-Avicci"]}
        onSelect={setMusic}
        label={music}
      />
      {music && (
        <>
          <button
            className="col-span-2 btn-secondary"
            onClick={() => sendMusic()}
          >
            Prep the music!
          </button>
          <button
            disabled={!musicPrepped}
            className={`col-span-2 btn ${
              !musicPrepped ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={() => startTheMusic()}
          >
            Start the music!
          </button>
        </>
      )}
    </>
  )
}
