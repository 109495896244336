import React, { useState } from "react"
import { connect } from "react-redux"
import Menu from "../../components/Menu"
import SelectTrack from "../../components/SelectTrack"
import Layout from "../../components/Layout"

function App({ count, audioReadyCount, musicReadyCount = 0 }) {

  const [ui, setUI] = useState(false)

  const getUI = () => {
    switch (ui) {
      case "track":
        return (
          <SelectTrack
            musicReadyCount={musicReadyCount}
            audioReadyCount={audioReadyCount}
          />
        )
      default:
        return <div />
    }
  }

  return (
    <Layout>
      <div className="grid grid-cols-2 gap-4">
        {ui ? (
          <>
            <button
              onClick={() => setUI(false)}
              className="col-span-2 flex items-center rounded text-teal-800 justify-center text-center"
            >
              <i className="text-xl las la-arrow-left mr-1"></i>
              <h1 className="text-sm">Back To Menu</h1>
            </button>
            {getUI()}
          </>
        ) : (
          <>
            <div className="col-span-2 flex items-center rounded text-teal-800 justify-center text-center">
              <i className="text-xl las la-magic mr-1"></i>
              <h1 className="text-sm">
                You are the <strong>conductor</strong>.
              </h1>
            </div>
            <div className="col-span-2 bg-green-200 p-5 rounded text-teal-800 text-center flex items-center justify-center">
              <i className="las la-mobile text-2xl mr-2"></i>
              {count} devices connected
            </div>
            <div className="col-span-2 sm:col-span-1  bg-teal-200 p-5 rounded text-teal-800 text-center flex items-center justify-center">
              <i className="las la-volume-up text-2xl mr-2"></i>
              {audioReadyCount} audio ready
            </div>
            <div
              className={`col-span-2 sm:col-span-1 ${
                audioReadyCount === musicReadyCount
                  ? "bg-teal-200 text-teal-800"
                  : "bg-orange-200 text-teal-800"
              } p-5 rounded  text-center flex items-center justify-center`}
            >
              <i className="las la-music text-2xl mr-2"></i>
              {musicReadyCount} music ready
            </div>
            <Menu onChange={setUI} />
          </>
        )}
      </div>
    </Layout>
  )
}

const mapStateToProps = ({ count, audioReadyCount }) => {
  return {
    count,
    audioReadyCount,
  }
}


const ConnectedApp =
  typeof window !== `undefined`
    ? connect(mapStateToProps, null)(App)
    : App

export default ConnectedApp
